<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            <span class="child"> Member / Renew Book </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center" style="margin:10px;"> Renew Book</v-card-title>
            <v-card-text v-for="item in booksList" :key='item'>
                <v-card>
                    <!-- <v-card-title class="heading"> {{item.accession_number}} - {{item.title}}</v-card-title> -->
                    <v-card-text>
                        <v-row>
                            <v-col sm="5" md="5" lg="5" >
                                <b>Book Accession No. : </b> {{item.accession_number}}<br>
                                <b>Title : </b> {{item.title}}<br>
                                <b>Authors : </b> <span v-for="name in item.author" :key='name.auth_name'>{{name.auth_names}}</span><br>
                                <b>Category : </b> {{item.bookcategory}}<br>
                                <b>Book Price : </b> {{item.price}}<br>
                                <span><b>Borrowed Date : </b> {{item.display_borrowing_date}}</span>
                                <span v-if="item.fine_amount!=0"><b>Fine Amount : </b> {{item.fine_amount}}</span>
                            </v-col>
                            <v-col sm="5" md="5" lg="5" >
                                <b>Publisher : </b> {{item.publisher}}<br>
                                <b>Book TYpe.</b> {{item.booktype}}<br>
                                <b>Book Format : </b> {{item.bookformat}}<br>
                                <b>No. of Copy Avaialable</b> -<br>
                                <b>No. of Pages : </b> {{item.numberofpages}}<br>
                                <span><b>Due Date : </b> {{item.displey_due_date}}</span>
                            </v-col>
                            <v-col sm="2" md="2" lg="2" >
                                <b>Book Bar Code : </b>  <br><vue-qrcode :value="item.accession_number" />
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col sm="4" md="4" lg="4" >
                                
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                
                            </v-col>
                        </v-row> -->
                        <!-- <v-row>
                            <v-col sm="4" md="4" lg="4" >
                                <b>Publisher : </b> {{item.publisher}}
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                <b>Book TYpe.</b> {{item.booktype}}
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                <b>Book Format : </b> {{item.bookformat}}
                            </v-col>
                        </v-row> -->
                        <!-- <v-row>
                            <v-col sm="4" md="4" lg="4" >
                                <b>Book Price : </b> {{item.price}}
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                <b>No. of Copy Avaialable</b> -
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                <b>No. of Pages : </b> {{item.numberofpages}}
                            </v-col>
                        </v-row> -->
                        <!-- <v-row>
                            <v-col sm="4" md="4" lg="4" >
                                <span><b>Borrowed Date : </b> <input class="p-0 m-0" id="datePickerId"  type="date" v-model="item.borrowing_date" /></span>
                                <datepicker class="example" placeholder="Select Date" v-model="item.borrowing_date"></datepicker></span>
                            </v-col>
                            <v-col sm="4" md="4" lg="4" >
                                <span><b>Due Date : </b> <input class="p-0 m-0" id="datePickerId"  type="date" v-model="item.due_date" /></span>
                                 <datepicker class="example" placeholder="Select Date" v-model="item.due_date"></datepicker></span>
                            </v-col>
                            <v-col sm="4" md="4" lg="4" v-if="item.fine_amount!=0" >
                               <span><b>Fine Amount : </b> {{item.fine_amount}}</span>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col sm="12" md="12" lg="12">
                                <center>
                                    <!-- {{item.renewbtn}} -->
                                    <!-- <v-btn v-if="item.fine_amount!=0" class="btn mb-2" color="info">Pay</v-btn> -->
                                    <!-- {{item.renewbtn}} -->
                                  
                                    <v-chip  class="btn mb-2" v-if="item.renewbtn" color="error">Book renew attempt is over please submit your book to Library</v-chip> 
                                    
                                    <v-btn  v-else class="btn mb-2"  color="success" @click="renewBook(item.accession_number)">Renew Book</v-btn>
                                
                                </center>
                                
                            </v-col>    
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-card>
<v-overlay :value="overlay">
           Loading please Wait.
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import VueQrcode from 'vue-qrcode'
    //import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            booksList:null,
        }),
        components: {
            VueQrcode,
            //Datepicker,
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Book" : "Edit Book";
            },
        },
        mounted() {
            this.onLoad();
            //datePickerId.min = new Date().toISOString().split("T")[0];
        },
        methods: {
            renewBook(item) {
      if (confirm("Are You Sure You Want To Renew Book?")) {
        const data = {
          a_no: item,
        };
        this.overlay = true;
        axios
          .post("/IssueBook/renewBook", data)
          .then((res) => {
            if (res.data.msg == "200") {
              this.showSnackbar("#4caf50", "Renew Book Successfully");
              this.overlay = false;
            this.onLoad();
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
            this.overlay = false;
          })
          .finally(() => {});
      }
    },
          
            onLoad() {
                this.overlay = true;
                axios
                    .get("/MemberProcess/getBooksData")
                    .then((res) => {
                        if (res.data.msg == "200") {
                             this.overlay = false;
                            this.booksList = res.data.booksList;

                        }
                        else{
                            this.showSnackbar("red", res.data.msg); // show snackbar
this.overlay = false;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                        this.overlay = false;
                    })
                    .finally(() => {
                        this.overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
           
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
</style>
